import {
    defaultDirection,
    defaultLocale,
    defaultColor,
    localeOptions,
    themeColorStorageKey,
    themeRadiusStorageKey
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
        const A = a[key];
        const B = b[key];
        if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
            return 1;
        }
        return -1;
    });
    return array;
};

export const getDateWithFormat = (timeStamp = new Date().getTime() / 1000) => {
    const today = new Date(timeStamp * 1000);
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!

    const yyyy = today.getFullYear();
    if (dd < 10) {
        dd = `0${dd}`;
    }
    if (mm < 10) {
        mm = `0${mm}`;
    }
    return `${dd}/${mm}/${yyyy}`;
};

export const getCurrentTime = (timeStamp = new Date().getTime() / 1000) => {
    const now = new Date(timeStamp * 1000);
    return `${now.getHours().toString().padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}`;
};

//in seconds
export const timeDifference = (date1, date2) => {

    let difference = date1 - date2;

    let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    let minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    let secondsDifference = Math.floor(difference / 1000);

    return `${hoursDifference.toString().padStart(2,"0")}:${minutesDifference.toString().padStart(2,"0")}:${secondsDifference.toString().padStart(2,"0")}`
}


export const getDirection = () => {
    let direction = defaultDirection;

    try {
        if (localStorage.getItem('direction')) {
            const localValue = localStorage.getItem('direction');
            if (localValue === 'rtl' || localValue === 'ltr') {
                direction = localValue;
            }
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
        direction = defaultDirection;
    }
    return {
        direction,
        isRtl: direction === 'rtl',
    };
};

export const setDirection = (localValue) => {
    let direction = 'ltr';
    if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
    }
    try {
        localStorage.setItem('direction', direction);
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
    }
};

export const getCurrentColor = () => {
    let currentColor = defaultColor;
    try {
        if (localStorage.getItem(themeColorStorageKey)) {
            currentColor = localStorage.getItem(themeColorStorageKey);
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error)
        currentColor = defaultColor
    }
    return currentColor;
};

export const setCurrentColor = (color) => {
    try {
        localStorage.setItem(themeColorStorageKey, color);
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
    }
};

export const getCurrentRadius = () => {
    let currentRadius = 'rounded';
    try {
        if (localStorage.getItem(themeRadiusStorageKey)) {
            currentRadius = localStorage.getItem(themeRadiusStorageKey);
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
        currentRadius = 'rounded'
    }
    return currentRadius;
};

export const setCurrentRadius = (radius) => {
    try {
        localStorage.setItem(themeRadiusStorageKey, radius);
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
    }
};

export const getCurrentLanguage = () => {
    let language = defaultLocale;
    try {
        language = localStorage.getItem('currentLanguage') &&
        localeOptions.filter(
            (x) => x.id === localStorage.getItem('currentLanguage')
        ).length > 0
            ? localStorage.getItem('currentLanguage')
            : defaultLocale;
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
        language = defaultLocale;
    }
    return language;
};

export const setCurrentLanguage = (locale) => {
    try {
        localStorage.setItem('currentLanguage', locale);
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error)
    }
};

export const getCurrentUser = () => {
    let user = null;
    try {
        user = localStorage.getItem('gogo_current_user') != null ? JSON.parse(localStorage.getItem('gogo_current_user')) : null;
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error)
        user = null;
    }
    return user;
};

export const setCurrentUser = (user) => {
    try {
        if (user) {
            localStorage.setItem('gogo_current_user', JSON.stringify(user))
        } else {
            localStorage.removeItem('gogo_current_user')
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error)
    }
};

export const GradeEnum = Object.freeze({
    1: "1st primary",
    2: "2nd primary",
    3: "3rd primary",
    4: "4th primary",
    5: "5th primary",
    6: "6th primary",
    7: "1st preparatory",
    8: "2nd preparatory",
    9: "3rd preparatory",
    10: "1st secondary",
    11: "2nd secondary sci",
    12: "3rd secondary",
    13: "2nd secondary art"
})

export const DaysOfTheWeekEnum = Object.freeze({
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
})
