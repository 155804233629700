import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { getCurrentUser } from './Utils';
// eslint-disable-next-line import/no-cycle
import { isAuthGuardActive } from '../constants/defaultValues';


export const UserPermissions = {
    AdminUsers: 1,
    Users: 2,
    Skeleton: 3,
    Content: 4,
    Courses: 5,
    Website: 6,
}



const ProtectedRoute = ({
  component: Component,
  roles = undefined,
  ...rest
}) => {
  const setComponent = (props) => {
    if (isAuthGuardActive) {
      const currentUser = getCurrentUser();
      if (currentUser) {
        if (roles) {
          if (roles.includes(currentUser.role)) {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/unauthorized',
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }
      return (
        <Redirect
          to={{
            pathname: '/user/login',
            state: { from: props.location },
          }}
        />
      );
    }
    return <Component {...props} />;
  };

  return <Route {...rest} render={setComponent} />;
};

// eslint-disable-next-line import/prefer-default-export
export { ProtectedRoute };
