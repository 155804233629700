import {UserPermissions} from "../helpers/authHelper";

export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
];

export const daysOfTheWeekOptions = [
  {label: 'Sunday', value: 0, key: 0},
  {label: 'Monday', value: 1, key: 1},
  {label: 'Tuesday', value: 2, key: 2},
  {label: 'Wednesday', value: 3, key: 3},
  {label: 'Thursday', value: 4, key: 4},
  {label: 'Friday', value: 5, key: 5},
  {label: 'Saturday', value: 6, key: 6},
]

export const adminUsersPermissions = [
  {label: 'Read Users', value: 1, key: 1},
  {label: 'Write Users', value: 2, key: 2},
  {label: 'Read Courses', value: 3, key: 3},
  {label: 'Write Courses', value: 4, key: 4},
  {label: 'Read Website', value: 5, key: 5},
  {label: 'Write Website', value: 6, key: 6},
  {label: 'Read Admin Users', value: 7, key: 7},
  {label: 'Write Admin Users', value: 8, key: 8},
]

export const termOptions = [
  {label: '1st term', value: 1, key: 1},
  {label: '2nd term', value: 2, key: 2},
  {label: 'no term', value: 3, key: 3},
]


export const adminRoot = '/admin';
export const buyUrl = '';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://www.mathacademycentre.com/apis/v1/';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.bluenavy';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
